<template>
  <v-container>
    <div
      class="my-6"
    >
      <v-img
        src="/favicons/favicon-96x96.png"
        max-width="42"
        class="mx-auto"
      />

      <div
        class="text-center font-weight-bold headline mt-2"
      >
        mestamaster
      </div>
    </div>

    <div
      v-if="$wait.is('authing')"
    >
      <m-loader-circular
        :text="fetchingUserLoaderText"
        :size="20"
        :width="2"
      />
    </div>

    <v-card
      v-else-if="userFound"
      max-width="400px"
      class="mx-auto"
    >
      <v-form
        ref="form"
        @submit.prevent="onSubmit"
      >
        <v-card-title
          v-translate
          translate-context="Page title (MPasswordResetPage)"
        >
          Set a new password
        </v-card-title>

        <v-card-subtitle
          v-translate
          translate-context="Page content (MPasswordResetPage)"
        >
          Set a new password for your account
        </v-card-subtitle>

        <v-card-text
          class="pt-4 pb-0"
        >
          <v-text-field
            v-model="password"
            :rules="[
              validators.required,
              validators.min(5),
            ]"
            :label="passwordPlaceholder"
            autofocus
            validate-on-blur
            outlined
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          />

          <v-text-field
            v-model="passwordConfirmation"
            :rules="[
              validators.required,
              validators.passwordConfirm(password, passwordConfirmation),
            ]"
            :label="passwordPlaceholder"
            validate-on-blur
            outlined
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          />
        </v-card-text>

        <v-card-actions
          class="px-4"
        >
          <v-btn
            :loading="$wait.is('resetting password')"
            :disabled="$wait.is('resetting password')"
            type="submit"
            color="primary"
            block
            large
          >
            <span
              v-translate
              translate-context="Page content (MPasswordResetPage) save button"
            >
              Save & sign in
            </span>
          </v-btn>
        </v-card-actions>

        <v-card-actions
          class="pb-4 px-4 text-center d-block caption text--secondary"
        >
          <span
            v-translate
            translate-context="Page content (MPasswordResetPage)"
          >
            You will be signed in automatically
          </span>
        </v-card-actions>

        <v-card-actions
          class="px-4"
        >
          <v-btn
            color="secondary"
            block
            small
            outlined
            @click="toFrontPage"
          >
            <span
              v-translate
              translate-context="Page content (MPasswordResetPage) to homepage button"
            >
              Cancel and go back to login page
            </span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-card
      v-else
      max-width="400px"
      class="mx-auto"
    >
      <v-card-title
        v-translate
        translate-context="Page title (MPasswordResetPage)"
        class="title"
        style="white-space: normal;"
      >
        Did you try to reset your password?
      </v-card-title>
      <v-card-text
        v-translate
        translate-context="Page content (MPasswordResetPage)"
      >
        It seems like your link has expired or is otherwise
        broken. Please request a new link from the homepage if you
        would like to reset your password.
      </v-card-text>
      <v-card-text>
        <v-btn
          color="primary"
          block
          large
          @click="toFrontPage"
        >
          <span
            v-translate
            translate-context="Page content (MPasswordResetPage) to homepage button"
          >
            Back to login page
          </span>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import { mapGetters } from 'vuex';
  import getValidators from '@/mixins/validators';

  export default {
    $_veeValidate: { validator: 'new' },

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      ...getValidators('required', 'min', 'passwordConfirm'),

      passwordValidationScope: 'user',
      showPassword: false,
      password: '',
      passwordConfirmation: '',
    }),

    computed: {
      ...mapGetters({
        user: 'currentUser/user',
      }),

      userFound() {
        return !!this.user.id;
      },

      fetchingUserLoaderText() {
        return this.$pgettext('Loader', 'Fetching user...');
      },

      passwordPlaceholder() {
        return this.$gettext('Password');
      },
    },

    created() {
      this.authWithToken({ resetToken: this.token });
    },

    methods: {
      ...mapWaitingActions('currentUser', {
        authWithToken: 'authing',
        resetPassword: 'resetting password',
      }),

      async onSubmit() {
        const valid = await this.$refs.form.validate();

        if (!valid) return;

        const success = await this.resetPassword({
          id: this.user.id,
          payload: {
            resetToken: this.token,
            user: {
              password: this.password,
              passwordConfirmation: this.passwordConfirmation,
            },
          },
        });

        if (!success) return;

        await this.$router.replace({
          name: 'projects-list',
        });
      },

      async toFrontPage() {
        await this.$router.replace({
          name: 'login',
        });
      },
    },
  };
</script>
